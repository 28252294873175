import React, { useState, useEffect } from "react";
import _ from "lodash";
import { AiFillEdit } from "react-icons/ai";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";
import { Input, FormGroup, Label, Form, Row, Col } from "reactstrap";
import { SampleData, SampleFormData } from "./assets";
import CustomModal from "components/Modal";
import PostModal from "./PostModal";
import VariantField from "./VariantField";
import ImageField from "./Image";

export default function ModelForm({ selectedModel, onSubmit, brands }) {
	const [tempForm, setTempForm] = useState(SampleData);
	const [tempSelectedPost, setTempSelectedPost] = useState(null);
	const [showChoosePost, setChoosePost] = useState(false);
	const [imageData, setImageData] = useState([]);

	useEffect(() => {
		if (selectedModel) {
			setTempForm(selectedModel);
		}
	}, [selectedModel]);

	const onChangeField = (key, val) => {
		let temp = _.cloneDeep(tempForm);
		temp[key] = val;

		setTempForm(temp);
	};

	return (
		<>
			<ChinaPressModalBody>
				<Form>
					<Row>
						<Col xl={6}>
							<FormGroup>
								<Label>Title</Label>
								<Input
									value={tempForm["title"]}
									type={"text"}
									onChange={(e) => onChangeField("title", e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col lg={6}>
							<FormGroup>
								<Label>Car Brand</Label>
								<Input
									type={"select"}
									value={tempForm["mywheels_car_brand_id"]}
									onChange={(e) =>
										onChangeField("mywheels_car_brand_id", +e.target.value)
									}>
									<option value=""></option>
									{brands.map((brandChild) => (
										<option value={brandChild.id} key={brandChild.id}>
											{brandChild.name}
										</option>
									))}
								</Input>
							</FormGroup>
						</Col>
						<Col xl={12}>
							<FormGroup>
								<Label>Description</Label>
								<Input
									value={tempForm["description"]}
									type={"textarea"}
									onChange={(e) => onChangeField("description", e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col xl={4} lg={6}>
							<FormGroup>
								<Label>
									Must Read Post
									<AiFillEdit
										style={{ cursor: "pointer" }}
										className="text-primary ml-2"
										onClick={() => setChoosePost(true)}
									/>
								</Label>
								<Input
									value={tempSelectedPost?.title ?? "-"}
									disabled={true}
									readOnly
								/>
							</FormGroup>
						</Col>
						{SampleFormData.map((form) => (
							<Col
								xl={form.type === "textarea" ? "12" : "4"}
								lg={form.type === "textarea" ? "12" : "6"}>
								<FormGroup key={`field_${form.key}`}>
									<Label>{form.label}</Label>
									{form.type !== "select" && (
										<Input
											type={form.type}
											value={tempForm[form.key]}
											onChange={(e) =>
												onChangeField(
													form.key,
													form.type !== "number"
														? e.target.value
														: +e.target.value
												)
											}
										/>
									)}
									{form.type === "select" && (
										<Input
											type={"select"}
											value={tempForm[form.key]}
											onChange={(e) =>
												onChangeField(
													form.key,
													e.target.value
												)
											}>
											<option value=""></option>
											{form.options.map((child) => (
												<option key={child} value={child}>
													{child}
												</option>
											))}
										</Input>
									)}
								</FormGroup>
							</Col>
						))}
						<Col lg={12}>
							<VariantField
								variantData={tempForm.variants}
								onChangeField={onChangeField}
							/>
							<ImageField
								imageData={imageData}
								onRemove={(url, index) => {
									let temp = _.cloneDeep(imageData);
									temp.splice(index, 1);

									setImageData(temp);
								}}
								onAddFile={(val) => {
									let temp = _.cloneDeep(imageData);
									temp.push({
										url: val,
									});

									setImageData(temp);
								}}
							/>
						</Col>
					</Row>
				</Form>
				<CustomModal
					size={"xl"}
					isOpen={showChoosePost}
					onClose={() => setChoosePost(false)}>
					<PostModal
						onSubmit={(val) => {
							setTempSelectedPost(val);
							onChangeField("posrt_id", val.id);
							setChoosePost(false);
						}}
						onClose={() => setChoosePost(false)}
					/>
				</CustomModal>
			</ChinaPressModalBody>
			<ChinaPressModalFooter
				rightButton={[
					{
						color: "primary",
						disabled: tempForm.brand_id === null || !tempForm.title,
						content: "Submit",
						onClick: () => onSubmit(tempForm, imageData),
					},
				]}
			/>
		</>
	);
}

import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import MyWheelsTable from "components/Table";
import HOC from "actions/hoc/brand";

function FurtherReadingPost({
	mode,
	postCarBrands,
	brands,
	getBrand,
	onSubmit,
}) {
	const [selectedBrand, setSelectedBrand] = useState(null);

	useEffect(() => {
		getBrand();
	}, []);

	const filtered = useMemo(() => {
		let temp = _.filter(brands, (brandChild) => {
			return (
				_.findIndex(postCarBrands, (postBrandChild) => {
					if (mode === "edit") {
						return postBrandChild.mywheels_car_brand_id === brandChild.id;
					} else {
						return postBrandChild.id === brandChild.id;
					}
				}) < 0
			);
		});

		return temp;
	}, [postCarBrands, brands]);

	console.log(postCarBrands);

	return (
		<>
			<CustomModalBody>
				<MyWheelsTable
					showPagination={true}
					data={filtered}
					columnsContent={[
						{
							Header: "Name",
							accessorKey: "name",
							centerColumn: true,
							Cell: (val) => <>{val.name}</>,
						},
					]}
					actionsContent={[
						{
							content: () => "Select",
							actionID: "SelectPost",
							colorFunc: (val) =>
								selectedBrand && val.id === selectedBrand.id
									? "primary"
									: "secondary",
							tooltipContent: "Select",
							onClick: (val) => {
								if (selectedBrand && val.id === selectedBrand.id) {
									return setSelectedBrand(null);
								}
								setSelectedBrand(val);
							},
						},
					]}
				/>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						content: "Submit",
						disabled: !selectedBrand,
						color: "primary",
						onClick: () => {
							onSubmit(selectedBrand);
						},
					},
				]}
			/>
		</>
	);
}

export default HOC(FurtherReadingPost);

import React, { useState, useEffect } from "react";
import _ from "lodash";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";
import { Input, FormGroup, Label } from "reactstrap";
import ImageField from "components/Input/File";

export default function Form({ selectedBrand, onSubmit }) {
	const [name, setName] = useState("");
	const [url, setUrl] = useState("");

	useEffect(() => {
		if (selectedBrand) {
			setName(selectedBrand?.name ?? "");
			setUrl(selectedBrand?.logo_url ?? "");
		}
	}, [selectedBrand]);

	return (
		<>
			<ChinaPressModalBody>
				<FormGroup>
					<Label>Name</Label>
					<Input value={name} onChange={(e) => setName(e.target.value)} />
				</FormGroup>
				<FormGroup>
					<ImageField
						label={"Brand Logo"}
						fileValue={url}
						onRemoveFile={() => setUrl("")}
						onSelectImage={(val) => setUrl(val)}
					/>
				</FormGroup>
			</ChinaPressModalBody>
			<ChinaPressModalFooter
				rightButton={[
					{
						color: "primary",
						disabled: !name,
						content: "Submit",
						onClick: () =>
							onSubmit(
								{
									name,
									logo_url: url,
								},
								selectedBrand?.id
							),
					},
				]}
			/>
		</>
	);
}

import React from "react";
import _ from "lodash";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Label,
	Input,
	Button,
	Row,
} from "reactstrap";
import { SampleVariant } from "./assets";

function VariantField({ variantData, onChangeField }) {
	const onChangeVariantField = (index, context, val) => {
		let temp = _.cloneDeep(variantData);
		temp[index][context] = val;

		onChangeField("variants", temp);
	};

	const onAddVariant = () => {
		let temp = _.cloneDeep(variantData);
		temp.push(SampleVariant);

		onChangeField("variants", temp);
	};

	return (
		<>
			<Card className="mt-2">
				<CardHeader>
					Variants
					<Button
						className="ml-auto"
						color="primary"
						onClick={() => onAddVariant()}>
						Add Variant
					</Button>
				</CardHeader>
				<CardBody>
					{variantData.map((variantChild, variantIndex) => (
						<Card className="p-3 mb-3">
							<Row>
								<Col xl={6} lg={6}>
									<FormGroup>
										<Label>Variant Name</Label>
										<Input
											value={variantChild.model}
											type={"text"}
											onChange={(e) =>
												onChangeVariantField(
													variantIndex,
													"model",
													e.target.value
												)
											}
										/>
									</FormGroup>
								</Col>
								<Col xl={6} lg={6}>
									<FormGroup>
										<Label>Price</Label>
										<Input
											value={variantChild.price}
											type={"number"}
											onChange={(e) =>
												onChangeVariantField(
													variantIndex,
													"price",
													e.target.value
												)
											}
										/>
									</FormGroup>
								</Col>
								<Col lg={12}>
									<FormGroup>
										<Label>Description</Label>
										<Input
											value={variantChild.description}
											type={"textarea"}
											onChange={(e) =>
												onChangeVariantField(
													variantIndex,
													"description",
													e.target.value
												)
											}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Card>
					))}
				</CardBody>
			</Card>
		</>
	);
}

export default VariantField;

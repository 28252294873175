import React, { useEffect, Fragment } from "react";
import _ from "lodash";
import {
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Row,
} from "reactstrap";

function Categories({ categories, postCategories, setPostcategories }) {
	return (
		<Card>
			<CardBody>
				<Label className="mb-2">Post Categories</Label>
				<hr />
				<Form>
					<Row>
						{categories.map((categoryChild) => (
							<Col lg={3} md={4} sm={6} key={`category_${categoryChild.id}`}>
								<FormGroup>
									<Label>
										<strong>{categoryChild.name}</strong>
									</Label>
									{categoryChild.children.map((subChild) => (
										<Fragment key={`category_child_${subChild.id}`}>
											<FormGroup style={{ marginLeft: "1.25rem" }}>
												<Input
													type={"checkbox"}
													color="primary"
													checked={
														_.findIndex(postCategories, {
															mywheels_category_id: subChild.id,
														}) > -1
													}
													onChange={() => {
														let temp = _.cloneDeep(postCategories);
														let tempIndex = _.findIndex(
															postCategories,
															(child) => {
																return (
																	child.mywheels_category_id === subChild.id
																);
															}
														);

														tempIndex < 0
															? (temp = [
																	...temp,
																	{
																		mywheels_category_id: subChild.id,
																	},
															  ])
															: temp.splice(tempIndex, 1);

														setPostcategories(temp);
													}}
												/>
												<Label>{subChild.name}</Label>
											</FormGroup>
										</Fragment>
									))}
								</FormGroup>
							</Col>
						))}
					</Row>
				</Form>
			</CardBody>
		</Card>
	);
}

export default Categories;

export const SampleVariant = {
	model: "Sample Title",
	description: "Sample desc",
	price: "0",
};

export const Type = [
	"Hybrid",
	"SUV",
	"MPV",
	"Sedan",
	"Coupe",
	"Compact",
	"Wagon",
	"Van",
	"Hatchback",
	"Pick-up",
	"Sport Coupe",
	"Convertible",
	"BEV",
	"B-Segment SUV",
	"Commercial",
	"A-Segment SUV",
];

export const Transmission = [
	"Auto",
	"Manual",
	"Hybrid",
	"Semi-manual",
	"CVT",
	"e-CVT",
	"8-speed-at",
	"DCT",
	"6-Speed AT",
	"7 speed ISR",
	"4 -speed",
	"7-Speed DCT",
	"4 -speed AT",
	"5 Speed Manual",
	"Auto/Manual",
	"9-Speed AT",
	"7-speed CVT",
	"5 speed-MT",
	"6 speed MT",
	"5-speed AT",
	"7-Speed AT",
	"10-Speed AT",
	"Automatic single-speed reduction gear",
	"8-Speed DCT",
	"6-Speed DCT",
	"8-Speed tiptronic",
	"8-Speed DVT",
	"10 Speed CVT",
	"7-Speed DSG",
];

export const Engine = [
	"1.0cc",
	"1.5cc",
	"1.8cc",
	"2.0cc",
	"2.5cc",
	"5.0cc",
	"Turbo",
	"2.4cc",
	"3.0cc",
	"1.3CC",
	"780CV",
	"1.6 cc",
	"3.5 cc",
	"2.2CC",
	"1.4T 150PS",
	"4.0",
	"2.7cc",
	"2.8cc",
	"2.9cc",
	"4.7CC",
	"1.2CC",
	"3.9CC",
	"6.0",
	"3.8CC",
	"3.4cc",
	"5.7CC",
	"3.7CC",
	"5.9",
	"5.2",
];

export const SampleData = {
	variants: [SampleVariant],
	mywheels_car_brand_id: null,
	post_id: 1,
	description: "",
	title: "",
	currency: "RM",
	engine: "",
	transmission_system: "",
	oil_consumption: "",
	comment_title: "",
	comment_description: "",
	slug: "",
	status: "active",
	body_type: "",
	manufacturing_year: "",
	engine_capacity: "",
	seller_type: "",
	engine_cylinder: "",
	engine_peak_power: "",
	engine_peak_torque: "",
	fuel_injection: "",
	aspiration: "",
	battery: "",
	fuel_type: "",
	brake_system: "",
	tyres: "",
	front_suspension: "",
	rear_suspension: "",
	exterior_colour: "",
	warranty: "",
	front_airbag: "",
	side_airbag: "",
	equipment_highlight: "",
	min_price: 0,
	max_price: 0,
	mileage: 0,
	length: 0,
	width: 0,
	height: 0,
	kerb_weight: 0,
	fuel_tank: 0,
	front_wheel_base: 0,
	rear_wheel_base: 0,
	market_price: 0,
	tyre_rim: 0,
	seats: 0,
};

export const SampleFormData = [
	{
		label: "Engine",
		key: "engine",
		type: "text",
	},
	{
		label: "Transmission System",
		key: "transmission_system",
		type: "select",
		options: Transmission,
	},
	{
		label: "Oil Consumption",
		key: "oil_consumption",
		type: "text",
	},
	{
		label: "Comment Title",
		key: "comment_title",
		type: "text",
	},
	{
		label: "Comment Description",
		key: "comment_description",
		type: "textarea",
	},
	{
		label: "Body Type",
		key: "body_type",
		type: "select",
		options: Type,
	},
	{
		label: "Manufacturing Year",
		key: "manufacturing_year",
		type: "text",
	},
	{
		label: "Engine Capacity",
		key: "engine_capacity",
		type: "select",
		options: Engine,
	},
	{
		label: "Seller Type",
		key: "seller_type",
		type: "text",
	},
	{
		label: "Engine Cylinder",
		key: "engine_cylinder",
		type: "text",
	},
	{
		label: "Engine Peak Power",
		key: "engine_peak_power",
		type: "text",
	},
	{
		label: "Engine Peak Torque",
		key: "engine_peak_torque",
		type: "text",
	},
	{
		label: "Fuel Injection",
		key: "fuel_injection",
		type: "text",
	},
	{
		label: "Aspiration",
		key: "aspiration",
		type: "text",
	},
	{
		label: "Battery",
		key: "battery",
		type: "text",
	},
	{
		label: "Fuel Type",
		key: "fuel_type",
		type: "text",
	},
	{
		label: "Brake System",
		key: "brake_system",
		type: "text",
	},
	{
		label: "Tyres",
		key: "tyres",
		type: "text",
	},
	{
		label: "Front Suspension",
		key: "front_suspension",
		type: "text",
	},
	{
		label: "Rear Suspension",
		key: "rear_suspension",
		type: "text",
	},
	{
		label: "Exterior Colour",
		key: "exterior_colour",
		type: "text",
	},
	{
		label: "Warranty",
		key: "warranty",
		type: "text",
	},
	{
		label: "Front Airbag",
		key: "front_airbag",
		type: "text",
	},
	{
		label: "Side Airbag",
		key: "side_airbag",
		type: "text",
	},
	{
		label: "Equipment Highlight",
		key: "equipment_highlight",
		type: "text",
	},
	{
		label: "Min Price ( RM )",
		key: "min_price",
		type: "number",
	},
	{
		label: "Max Price ( RM )",
		key: "max_price",
		type: "number",
	},
	{
		label: "Mileage",
		key: "mileage",
		type: "number",
	},
	{
		label: "Length",
		key: "length",
		type: "number",
	},
	{
		label: "Width",
		key: "width",
		type: "number",
	},
	{
		label: "Height",
		key: "height",
		type: "number",
	},
	{
		label: "Kerb Weight",
		key: "kerb_weight",
		type: "number",
	},
	{
		label: "Fuel Tank",
		key: "fuel_tank",
		type: "number",
	},
	{
		label: "Front Wheel Base",
		key: "front_wheel_base",
		type: "number",
	},
	{
		label: "Rear Wheel Base",
		key: "rear_wheel_base",
		type: "number",
	},
	{
		label: "Market Price",
		key: "market_price",
		type: "number",
	},
	{
		label: "Tyre Rim",
		key: "tyre_rim",
		type: "number",
	},
	{
		label: "Seats",
		key: "seats",
		type: "number",
	},
];

import React, { Component } from "react";
import { connect } from "react-redux";

import { storeItem } from "utils/tokenStore";
import { setProfile } from "actions/profile";

import Axios from "axios";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			showPassword: false,
			showErrorMessage: false,
		};

		onChangeHOC = (val, context) => this.setState({ [context]: val });

		onClickLogin = ({ platform, email, password }) => {
			Axios.post(
				`https://chinapress-cloud-staging.navarch.app/api/auth-user?platform=${platform}`,
				{
					email,
					password,
					encode_type: "JWT",
				}
			)
				.then((response) => {
					this.setState({ loading: false });
					this.props.setProfile(response.data.user);
					storeItem("CHINA_PRESS_TOKEN", response.data.access_token);
					if (response.data.user.platform === "servicemacha") {
						return this.props.history.push("/dashboard/service-macha/post");
					}

					if (response.data.user.platform === "jobmacha") {
						return this.props.history.push("/dashboard/job-macha/post");
					}

					return this.props.history.push("/dashboard/post");
				})
				.catch((error) => {
					this.setState({ showErrorMessage: true, loading: false });
				});
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					showPassword={this.state.showPassword}
					showErrorMessage={this.state.showErrorMessage}
					onLoadLogin={this.state.loading}
					onChangeHOC={this.onChangeHOC}
					onClickLogin={this.onClickLogin}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, {
		setProfile,
	})(WithHOC);
};
export default HOC;

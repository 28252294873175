import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { Get, Put, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { queryHandler } from "utils/queryHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			videos: [],
			pageIndex: 1,
			selectedVideo: null,
			showEditVideoModal: false,
			selectedDeleteVideo: null,
			videoCounts: 0,
			showCreateVideoModal: false,
			searchParams: [
				{
					label: "Title",
					value: `title`,
					type: "text",
					param: ``,
					col: 6,
				},
				{
					label: "Category",
					value: `slug_category_id`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				},
				{
					label: "From Date (Published)",
					value: `published_datetype=between&published_start_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
				{
					label: "To Date (Published)",
					value: `published_end_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
				{
					label: "From Date (Scheduled)",
					value: `datetype=between&start_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
				{
					label: "To Date (Scheduled)",
					value: `end_at`,
					type: "date",
					param: null,
					defaultVal: null,
					col: 6,
				},
			],
		};

		onChangeVideoHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getVideo = (val) => {
			Get(
				`/non-deleted-posts?${
					!val
						? `type=video&platform=myWheels&offset=${
								(this.state.pageIndex - 1) * 10
						  }&limit=10`
						: `type=video&platform=myWheels${val}&offset=${
								(this.state.pageIndex - 1) * 10
						  }&limit=10`
				}`,
				this.getVideoSuccess,
				this.getVideoError,
				this.load
			);
		};
		getVideoSuccess = (payload) => {
			this.setState({
				videos: payload.data.rows,
				videoCounts: payload.data.count,
			});
		};
		getVideoError = (error) => requestError(error);

		getSelectedVideo = (val) => {
			Get(
				`/posts/single?slug=${val}`,
				this.getSelectedVideoSuccess,
				this.getSelectedVideoError,
				this.load
			);
		};
		getSelectedVideoSuccess = (payload) =>
			this.setState({
				selectedVideo: {
					...payload.data,
					mywheels_tags: [],
				},
				showEditVideoModal: true,
			});
		getSelectedVideoError = (error) => requestError(error);

		updateVideo = (dataToSubmit) => {
			Put(
				`/posts/${dataToSubmit.id}`,
				{
					...dataToSubmit,
					further_reading_post_id: null,
					name: JSON.stringify(dataToSubmit.name),
				},
				this.updateVideoSuccess,
				this.updateVideoError,
				this.load
			);
		};
		updateVideoSuccess = () => {
			requestSuccess("Record has been updated successfully.");
			let tempQeuery = queryHandler(this.state.searchParams);

			this.setState({
				selectedDeleteVideo: null,
			});
			this.getVideo(tempQeuery);
		};
		updateVideoError = (error) => requestError(error);

		handleCarModel = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/post/car-brand-models`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		handleCarBrands = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/post/car-brands`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		handleCreatePostCat = (payload) => {
			return new Promise((resolve, reject) => {
				Post(
					`/mywheels/categories-posts`,
					payload,
					(payload) => resolve(payload),
					(error) => reject(error),
					this.load
				);
			});
		};

		createVideo = (
			dataToSubmit,
			postCategories,
			postCarModels,
			postCarBrands
		) => {
			Post(
				`/posts`,
				{
					...dataToSubmit,
					name: JSON.stringify(dataToSubmit.name),
				},
				(payload) =>
					this.createVideoSuccess(
						payload,
						postCategories,
						postCarModels,
						postCarBrands
					),
				this.createVideoError,
				this.load
			);
		};
		createVideoSuccess = (
			payload,
			postCategories,
			postCarModels,
			postCarBrands
		) => {
			if (postCategories.length > 0) {
				let postCatApi = [];
				for (let index = 0; index < postCategories.length; index++) {
					postCatApi.push(
						this.handleCreatePostCat({
							...postCategories[index],
							post_id: payload.data.id,
						})
					);
				}
				Promise.all(postCatApi)
					.then((responses) => {
						console.log(responses);
					})
					.catch((error) => {
						console.error(error);
					});
			}

			if (postCarModels.length > 0) {
				let tempApiCall = [];

				for (let index = 0; index < postCarModels.length; index++) {
					tempApiCall.push(
						this.handleCarModel({
							mywheels_car_brand_model_id: postCarModels[index]?.id,
							post_id: payload.data.id,
						})
					);
				}
			}

			if (postCarBrands.length > 0) {
				let tempApiCall = [];

				for (let index = 0; index < postCarBrands.length; index++) {
					tempApiCall.push(
						this.handleCarBrands({
							mywheels_car_brand_id: postCarBrands[index]?.id,
							post_id: payload.data.id,
						})
					);
				}
			}

			requestSuccess("Record has been created successfully.");
			this.setState({ showCreateVideoModal: false }, () => {
				this.getSelectedVideo(payload.data.slug);
			});
			let tempQeuery = queryHandler(this.state.searchParams);

			this.getVideo(tempQeuery);
		};
		createVideoError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					selectedDeleteVideo={this.state.selectedDeleteVideo}
					videoCounts={this.state.videoCounts}
					pageIndex={this.state.pageIndex}
					videos={this.state.videos}
					searchParams={this.state.searchParams}
					showCreateVideoModal={this.state.showCreateVideoModal}
					showEditVideoModal={this.state.showEditVideoModal}
					selectedVideo={this.state.selectedVideo}
					onLoadVideo={this.state.loading}
					getVideo={this.getVideo}
					updateVideo={this.updateVideo}
					createVideo={this.createVideo}
					onChangeVideoHOC={this.onChangeVideoHOC}
					getSelectedVideo={this.getSelectedVideo}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;

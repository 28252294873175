import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	Col,
	Row,
} from "reactstrap";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { queryHandler } from "utils/queryHandler";

const SearchForm = ({ searchParams, onChangeHOCState, getListAPI }) => {
	return (
		<Card className="main-card mb-3">
			<CardHeader style={{ textTransform: "capital" }}>Search</CardHeader>
			<CardBody>
				<Form onSubmit={(e) => e.preventDefault()}>
					<Row>
						{searchParams.map((item, index) => {
							return (
								<Col key={index} md={item.col || 12} xs={12}>
									<FormGroup key={item.value}>
										<Label>{item.label}</Label>
										{item.type === "select" && (
											<Input
												type={"select"}
												value={item.param}
												onChange={(e) => {
													let tmp = _.cloneDeep(searchParams);

													let tempVal = parseInt(e.target.value);

													if (item.valueType === "string") {
														tempVal = e.target.value;
													}
													if (item.valueType === "boolean") {
														tempVal = e.target.value === "true";
													}

													tmp[index]["param"] = tempVal;
													onChangeHOCState("searchParams", tmp);
												}}>
												<option></option>
												{item.options.map((data) => (
													<option key={data.id} value={data.id}>
														{data.name}
													</option>
												))}
											</Input>
										)}
										{item.type === "text" && (
											<Input
												type={"text"}
												value={item.param}
												onChange={(e) => {
													let tmp = _.cloneDeep(searchParams);
													tmp[index]["param"] = e.target.value;
													onChangeHOCState("searchParams", tmp);
												}}
											/>
										)}
										{item.type === "date" && (
											<DatePicker
												dateFormat="dd-MM-yyyy"
												showYearDropdown
												selected={
													item.param === null ? null : new Date(item.param)
												}
												className="form-control"
												onChange={(val) => {
													let tmp = _.cloneDeep(searchParams);
													tmp[index]["param"] = val;
													onChangeHOCState("searchParams", tmp);
												}}
											/>
										)}
									</FormGroup>
								</Col>
							);
						})}
					</Row>
					<div className="d-flex">
						<Button
							color="primary"
							style={{ marginLeft: "auto" }}
							onClick={async () => {
								let tempQuery = await queryHandler(searchParams);
								getListAPI(tempQuery);
							}}>
							Search
						</Button>
						<Button
							color="danger"
							style={{ marginLeft: "10px" }}
							onClick={async () => {
								let tmp = [];
								let tmpSearchParams = _.cloneDeep(searchParams);
								tmpSearchParams.map((item) => {
									tmp.push({
										...item,
										param: item.type === "date" ? item.defaultVal : "",
									});
								});
								let tempQuery = await queryHandler(tmp);
								onChangeHOCState("searchParams", tmp);
								onChangeHOCState("pageIndex", 1);
								getListAPI(tempQuery);
							}}>
							Reset
						</Button>
					</div>
				</Form>
			</CardBody>
		</Card>
	);
};

export default SearchForm;

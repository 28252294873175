import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			brands: [],
			selectedBrand: {},
			showCreateBrandModal: false,
			showEditBrandModal: false,
		};

		onChangeBrandHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getBrand = () => {
			Get(
				`/mywheels/car-brands`,
				this.getBrandSuccess,
				this.getBrandError,
				this.load
			);
		};
		getBrandSuccess = (payload) => this.setState({ brands: payload.data.rows });
		getBrandError = (error) => requestError(error);

		getSelectedBrand = (id) =>
			Get(
				`/mywheels/car-brands`,
				this.getSelectedBrandSuccess,
				this.getSelectedBrandError,
				this.load
			);
		getSelectedBrandSuccess = (payload) =>
			this.setState({ selectedBrand: payload });
		getSelectedBrandError = (error) => requestError(error);

		createBrand = (dataToSubmit) =>
			Post(
				`/mywheels/car-brands`,
				dataToSubmit,
				this.createBrandSuccess,
				this.createBrandError,
				this.load
			);
		createBrandSuccess = () => {
			this.getBrand();
			requestSuccess("Brand has been created successfully.");
		};
		createBrandError = (error) => requestError(error);

		updateBrand = (dataToSubmit, id) =>
			Put(
				`/mywheels/car-brands/${id}`,
				dataToSubmit,
				this.updateBrandSuccess,
				this.updateBrandError,
				this.load
			);
		updateBrandSuccess = (payload) => {
			this.getBrand();
			this.getSelectedBrand(payload.id);
			requestSuccess("Brand has been updated successfully.");
		};
		updateBrandError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					brands={this.state.brands}
					selectedBrand={this.state.selectedBrand}
					onLoadBrand={this.state.loading}
					showCreateBrandModal={this.state.showCreateBrandModal}
					showEditBrandModal={this.state.showEditBrandModal}
					getBrand={this.getBrand}
					getSelectedBrand={this.getSelectedBrand}
					createBrand={this.createBrand}
					updateBrand={this.updateBrand}
					onChangeBrandHOC={this.onChangeBrandHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;

import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import Moment from "moment";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import Pagination from "components/Pagination";
import CreateVideosForm from "./Create/General";
import EditVideoform from "./Edit/General";
import SearchForm from "components/SearchForm";
import CustomConfirmationForm from "components/Modal/confirmation.js";

import VideoHOC from "./actions/index";
import CategoryHOC from "./actions/category";
import TagHOC from "./actions/tag";
import "./index.scss";
import { queryHandler } from "utils/queryHandler";

class Video extends Component {
	componentDidMount = () => {
		let tempQeuery = queryHandler(this.props.searchParams);

		this.props.getVideo(tempQeuery);
		this.props.getTag();
		this.props.getCategories();
	};

	componentDidUpdate = (pp) => {
		if (
			pp.flattenCategories !== this.props.flattenCategories &&
			this.props.flattenCategories?.[0]
		) {
			let tempIndex = _.findIndex(
				this.props.searchParams,
				(val) => val.value === "slug_category_id"
			);
			if (tempIndex > -1) {
				let tempSearchParams = _.cloneDeep(this.props.searchParams);
				tempSearchParams[tempIndex]["options"] = this.props.flattenCategories;

				this.props.onChangeVideoHOC("searchParams", tempSearchParams);
			}
		}

		if (pp.pageIndex !== this.props.pageIndex) {
			let tempQeuery = queryHandler(this.props.searchParams);

			this.props.getVideo(tempQeuery);
		}
	};

	renderCreateVideo = () => {
		return (
			<ChinaPressModal
				fullScreen={true}
				isOpen={this.props.showCreateVideoModal}
				onClose={() =>
					this.props.onChangeVideoHOC("showCreateVideoModal", false)
				}>
				<CreateVideosForm
					userID={this.props.data.ProfileReducer.profile.id}
					tags={this.props.tags}
					categories={this.props.categories}
					onSubmit={this.props.createVideo}
					createTag={this.props.createTag}
					flattenCategories={this.props.flattenCategories}
				/>
				{this.props.onLoadVideo && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderEditVideo = () => {
		return (
			<ChinaPressModal
				fullScreen={true}
				isOpen={this.props.showEditVideoModal}
				onClose={() => {
					this.props.onChangeVideoHOC("showEditVideoModal", false);
					this.props.onChangeVideoHOC("selectedVideo", null);
					this.props.onChangeVideoHOC("selectedVideoTags", []);
				}}>
				<EditVideoform
					selectedVideo={this.props.selectedVideo}
					userID={this.props.data.ProfileReducer.profile.id}
					tags={this.props.tags}
					categories={this.props.categories}
					createTag={this.props.createTag}
					onSubmit={this.props.updateVideo}
					selectedVideoTags={this.props.selectedVideoTags}
					getVideoTags={this.props.getVideoTags}
					addVideoTag={this.props.addVideoTag}
					flattenCategories={this.props.flattenCategories}
					deleteVideoTag={this.props.deleteVideoTag}
				/>
				{this.props.onLoadVideo && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderVideoTable = () => {
		return (
			<MyWheelsTable
				data={this.props.videos}
				showPagination={false}
				columnsContent={[
					{
						Header: "Title",
						accessorKey: "title",
						centerColumn: true,
						Cell: (val) => <>{val.title || "N/A"}</>,
					},
					{
						Header: "Pin",
						accessorKey: "position",
						centerColumn: true,
						Cell: (val) => val.position || "-",
					},
					{
						Header: "Category",
						accessorKey: "slug_category_id",
						centerColumn: true,
						Cell: (val) => val?.slug_category?.name ?? "-",
					},
					{
						Header: "Author",
						centerColumn: true,
						Cell: (val) => val?.created_by_user?.name ?? "-",
					},
					{
						Header: "Tags",
						accessorKey: "tags",
						centerColumn: true,
						Cell: (val) => (
							<>
								{val.tags.map((tagChild) => (
									<p className="mb-0">{`- ${tagChild.name}`}</p>
								))}
								{val.tags.length < 1 && <span>-</span>}
							</>
						),
					},
					{
						Header: "View",
						centerColumn: true,
						accessorKey: "views",
						accessor: "views",
					},
					{
						Header: "Status",
						accessorKey: "status",
						centerColumn: true,
						Cell: (val) => (
							<>
								<span
									className={`badge ${
										val.status === "live" ? "badge-success" : "badge-secondary"
									}`}>
									{val.status}
								</span>
							</>
						),
					},
					{
						Header: "Schedule Date",
						centerColumn: true,
						accessorKey: "start_at",
						Cell: (val) => (
							<>
								{val.start_at
									? Moment(val.start_at).format("DD-MM-YYYY h:mm a")
									: "-"}
							</>
						),
					},
					{
						Header: "Published Date",
						centerColumn: true,
						accessorKey: "published_at",
						Cell: (val) => (
							<>
								{val.published_at
									? Moment(val.published_at).format("DD-MM-YYYY h:mm a")
									: "-"}
							</>
						),
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditVideo",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.getSelectedVideo(val.slug);
						},
					},
					{
						content: <i className="pe-7s-trash btn-icon-wrapper"> </i>,
						actionID: "DeleteVideo",
						color: "danger",
						tooltipContent: "Delete Video",
						onClick: (val) => {
							this.props.onChangeVideoHOC("selectedDeleteVideo", val);
						},
					},
					{
						content: <i className="pe-7s-look btn-icon-wrapper"> </i>,
						actionID: "PreviewPost",
						color: "primary",
						tooltipContent: "Preview Post",
						onClick: (val) => {
							window.open(
								`https://mywheels-dev.navarch.app/preview${val.slug}`,
								"_blank"
							);
						},
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading="Videos"
					subheading="Listings of all the posts present in the system."
					icon="pe-7s-video icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeVideoHOC("showCreateVideoModal", true);
							},
							content: (
								<>
									<span>Create Video</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<SearchForm
								searchParams={this.props.searchParams}
								onChangeHOCState={this.props.onChangeVideoHOC}
								getListAPI={this.props.getVideo}
							/>
							<Card className="main-card mb-3">
								<CardBody>
									{this.renderVideoTable()}
									<Pagination
										pageIndex={this.props.pageIndex}
										totalCount={this.props.videoCounts}
										onChangePage={(val) =>
											this.props.onChangeVideoHOC("pageIndex", val)
										}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreateVideo()}
				{this.renderEditVideo()}
				<CustomConfirmationForm
					open={this.props.selectedDeleteVideo !== null}
					title={"Remove post"}
					loading={this.props.onLoadAssets}
					message={"Are you sure to move the selected post to the bin?"}
					onClose={() =>
						this.props.onChangeVideoHOC("selectedDeleteVideo", null)
					}
					onClickConfirm={() => {
						this.props.updateVideo({
							...this.props.selectedDeleteVideo,
							status: "deleted",
						});
					}}
				/>

				{(this.props.onLoadVideo ||
					this.props.onLoadCategory ||
					this.props.onLoadTags) && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(VideoHOC, CategoryHOC, TagHOC)(Video);

import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			models: []
		};

		onChangeModelHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getModel = () => {
			Get(
				`/mywheels/car-brand-models`,
				this.getModelSuccess,
				this.getModelError,
				this.load
			);
		};
		getModelSuccess = (payload) => this.setState({ models: payload.data.rows });
		getModelError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					models={this.state.models}
					onLoadModel={this.state.loading}
					getModel={this.getModel}
					onChangeModelHOC={this.onChangeModelHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;

import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody } from "reactstrap";
import Moment from "moment";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import MyWheelsTable from "components/Table";
import PageTitle from "components/Title/PageTitle";
import ChinaPressModal from "components/Modal";
import CreatePostsForm from "./Create/General";
import EditPostform from "./Edit/General";
import SearchForm from "components/SearchForm";
import Pagination from "components/Pagination";
import CustomConfirmationForm from "components/Modal/confirmation.js";

import PostHOC from "./actions/index";
import CategoryHOC from "./actions/category";
import TagHOC from "./actions/tags";
import "./index.scss";
import { queryHandler } from "utils/queryHandler";

class Post extends Component {
	componentDidMount = () => {
		let tempQeuery = queryHandler(this.props.searchParams);

		this.props.getPost(tempQeuery);
		this.props.getTag();
		this.props.getCategories();
	};

	componentDidUpdate = (pp) => {
		if (
			pp.flattenCategories !== this.props.flattenCategories &&
			this.props.flattenCategories?.[0]
		) {
			let tempIndex = _.findIndex(
				this.props.searchParams,
				(val) => val.value === "slug_category_id"
			);
			if (tempIndex > -1) {
				let tempSearchParams = _.cloneDeep(this.props.searchParams);
				tempSearchParams[tempIndex]["options"] = this.props.flattenCategories;

				this.props.onChangePostHOC("searchParams", tempSearchParams);
			}
		}

		if (pp.pageIndex !== this.props.pageIndex) {
			let tempQeuery = queryHandler(this.props.searchParams);

			this.props.getPost(tempQeuery);
		}
	};

	isTwitterWidgetScriptLoaded() {
		const scripts = document.getElementsByTagName("script");
		for (let i = 0; i < scripts.length; i++) {
			if (scripts[i].src === "https://platform.twitter.com/widgets.js") {
				return true;
			}
		}
		return false;
	}

	renderCreatePost = () => {
		return (
			<ChinaPressModal
				fullScreen={true}
				isOpen={this.props.showCreatePostModal}
				onClose={() =>
					this.props.onChangePostHOC("showCreatePostModal", false)
				}>
				<CreatePostsForm
					userID={this.props.data.ProfileReducer.profile.id}
					tags={this.props.tags}
					categories={this.props.categories}
					onSubmit={this.props.createPost}
					createTag={this.props.createTag}
					flattenCategories={this.props.flattenCategories}
				/>
				{this.props.onLoadPost && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderEditPost = () => {
		return (
			<ChinaPressModal
				fullScreen={true}
				isOpen={this.props.showEditPostModal}
				onClose={() => {
					this.props.onChangePostHOC("showEditPostModal", false);
					this.props.onChangePostHOC("selectedPost", null);
					this.props.onChangePostHOC("selectedPostTags", []);
				}}>
				<EditPostform
					selectedPost={this.props.selectedPost}
					userID={this.props.data.ProfileReducer.profile.id}
					tags={this.props.tags}
					categories={this.props.categories}
					createTag={this.props.createTag}
					onSubmit={this.props.updatePost}
					selectedPostTags={this.props.selectedPostTags}
					getPostTags={this.props.getPostTags}
					addPostTag={this.props.addPostTag}
					flattenCategories={this.props.flattenCategories}
					deletePostTag={this.props.deletePostTag}
				/>
				{this.props.onLoadPost && <LoadingOverlay />}
			</ChinaPressModal>
		);
	};

	renderPostTable = () => {
		return (
			<MyWheelsTable
				showPagination={false}
				data={this.props.posts}
				columnsContent={[
					{
						Header: "Title",
						accessorKey: "title",
						centerColumn: true,
						Cell: (val) => <>{val.title || "N/A"}</>,
					},
					{
						Header: "Pin",
						centerColumn: true,
						accessorKey: "position",
						Cell: (val) => val.position || "-",
					},
					{
						Header: "Category",
						centerColumn: true,
						accessorKey: "slug_category_id",
						Cell: (val) =>
							_.find(this.props.flattenCategories, (temp) => {
								return temp.id === val.slug_category_id;
							})?.name ?? "-",
					},
					{
						Header: "Author",
						centerColumn: true,
						Cell: (val) => val?.created_by_user?.name ?? "-",
					},
					{
						Header: "Tags",
						centerColumn: true,
						accessorKey: "tags",
						Cell: (val) => (
							<>
								{val.tags.map((tagChild) => (
									<p className="mb-0">{`- ${tagChild.name}`}</p>
								))}
								{val.tags.length < 1 && <span>-</span>}
							</>
						),
					},
					{
						Header: "View",
						centerColumn: true,
						accessorKey: "views",
						accessor: "views",
					},
					{
						Header: "Status",
						centerColumn: true,
						accessorKey: "status",
						Cell: (val) => (
							<>
								<span
									className={`badge ${
										val.status === "live" ? "badge-success" : "badge-secondary"
									}`}>
									{val.status}
								</span>
							</>
						),
					},
					{
						Header: "Schedule Date",
						centerColumn: true,
						accessorKey: "start_at",
						Cell: (val) => (
							<>
								{val.start_at
									? Moment(val.start_at).format("DD-MM-YYYY h:mm a")
									: "-"}
							</>
						),
					},
					{
						Header: "Published Date",
						centerColumn: true,
						accessorKey: "published_at",
						Cell: (val) => (
							<>
								{val.published_at
									? Moment(val.published_at).format("DD-MM-YYYY h:mm a")
									: "-"}
							</>
						),
					},
				]}
				actionsContent={[
					{
						content: <i className="pe-7s-pen btn-icon-wrapper"> </i>,
						actionID: "EditPost",
						color: "primary",
						tooltipContent: "Edit",
						onClick: (val) => {
							this.props.getSelectedPost(val.slug);
						},
					},
					{
						content: <i className="pe-7s-trash btn-icon-wrapper"> </i>,
						actionID: "DeletePost",
						color: "danger",
						tooltipContent: "Delete Post",
						onClick: (val) => {
							this.props.onChangePostHOC("selectedDeletePost", val);
						},
					},
					{
						content: <i className="pe-7s-look btn-icon-wrapper"> </i>,
						actionID: "PreviewPost",
						color: "primary",
						tooltipContent: "Preview Post",
						onClick: (val) => {
							window.open(
								`https://mywheels-dev.navarch.app/preview${val.slug}`,
								"_blank"
							);
						},
					},
				]}
			/>
		);
	};

	render = () => {
		return (
			<>
				<PageTitle
					heading="Posts"
					subheading="Listings of all the posts present in the system."
					icon="pe-7s-pin icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "primary",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangePostHOC("showCreatePostModal", true);
							},
							content: (
								<>
									<span>Create Post</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Row>
						<Col md={12}>
							<SearchForm
								searchParams={this.props.searchParams}
								onChangeHOCState={this.props.onChangePostHOC}
								getListAPI={this.props.getPost}
							/>
							<Card className="main-card mb-3">
								<CardBody>
									{this.renderPostTable()}
									<Pagination
										pageIndex={this.props.pageIndex}
										totalCount={this.props.postCounts}
										onChangePage={(val) =>
											this.props.onChangePostHOC("pageIndex", val)
										}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ReactCSSTransitionGroup>
				{this.renderCreatePost()}
				{this.renderEditPost()}
				<CustomConfirmationForm
					open={this.props.selectedDeletePost !== null}
					title={"Remove post"}
					loading={this.props.onLoadPost}
					message={"Are you sure to move the selected post to the bin?"}
					onClose={() => this.props.onChangePostHOC("selectedDeletePost", null)}
					onClickConfirm={() => {
						this.props.updatePost({
							...this.props.selectedDeletePost,
							status: "deleted",
						});
					}}
				/>
				{(this.props.onLoadPost ||
					this.props.onLoadCategory ||
					this.props.onLoadTags) && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(PostHOC, CategoryHOC, TagHOC)(Post);

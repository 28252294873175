import React, { useState, Fragment, useRef } from "react";
import _ from "lodash";
import Moment from "moment";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
	Form,
	Input,
	FormGroup,
	Label,
	Row,
	Col,
	CardBody,
	Card,
	CardHeader,
	Button,
} from "reactstrap";
import {
	AiOutlinePlus,
	AiOutlineCloseCircle,
	AiFillEdit,
	AiFillDelete,
} from "react-icons/ai";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";

import { SamplePostData } from "../assets";
import ImageField from "components/Input/File";
import CustomModal from "components/Modal";
import FurtherReadingModal from "../Modal/FurtherReadingModal";
import VideoModal from "../Modal/VideoModal";
import RTE from "components/Input/RTE";
import CategoryContent from "./Category";
import BulkImageField from "../ImageField";

import "react-datepicker/dist/react-datepicker.css";

export default function PostForm({
	userID,
	tags,
	categories,
	onSubmit,
	createTag,
}) {
	const typeaheadRef = useRef();
	const [showChoosePost, setChoosePost] = useState(false);
	const [tempSelectedPost, setTempSelectedPost] = useState(null);
	const [postForm, setPostForm] = useState({
		...SamplePostData,
		created_by: userID,
	});

	const [typeaheadSearch, setTypeaheadSearch] = useState("");
	const [allowToCreateTag, setAllowCreate] = useState(false);

	const [postVideos, setPostVideo] = useState([]);
	const [showAddVideo, setShowAddVideo] = useState(false);
	const [postCategories, setPostcategories] = useState([]);
	const [selectedPostVideoIndex, setSelectedPostVideoIndex] = useState(null);
	const [imageData, setImageData] = useState([]);

	const onChangeField = (key, val) => {
		setPostForm({
			...postForm,
			[key]: val,
		});
	};

	return (
		<>
			<CustomModalBody fullScreen={true}>
				<Form>
					<Row>
						<Col md={12} lg={6} xl={8}>
							<FormGroup>
								<Label>
									Title
									<span className="text-danger">*</span>
								</Label>
								<Input
									placeholder="Add Title"
									value={postForm.title}
									onChange={(e) => onChangeField("title", e.target.value)}
								/>
							</FormGroup>
							<FormGroup>
								<RTE
									data={postForm.content}
									height={"500px"}
									formLabel={"Post Content"}
									disabled={false}
									onChange={(event, editor) =>
										onChangeField("content", editor.getData() || "")
									}
								/>
							</FormGroup>
							<FormGroup>
								<ImageField
									label={"Cover Image with Title"}
									fileValue={postForm.cover_img_with_title_url}
									onRemoveFile={() =>
										onChangeField("cover_img_with_title_url", "")
									}
									onSelectImage={(val) => {
										onChangeField("cover_img_with_title_url", val);
									}}
								/>
							</FormGroup>
							<FormGroup>
								<ImageField
									label={"Cover Image without Title"}
									fileValue={postForm.cover_img_without_title_url}
									onRemoveFile={() =>
										onChangeField("cover_img_without_title_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("cover_img_without_title_url", val)
									}
								/>
							</FormGroup>
							{/* <FormGroup>
								<ImageField
									label={"Article Feature Image"}
									fileValue={postForm.article_feature_img_url}
									onRemoveFile={() =>
										onChangeField("article_feature_img_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("article_feature_img_url", val)
									}
								/>
							</FormGroup> */}
							<FormGroup>
								<Label>
									Further Reading Posts
									<AiFillEdit
										style={{ cursor: "pointer" }}
										className="text-primary ml-2"
										onClick={() => setChoosePost(true)}
									/>
								</Label>
								<Input
									value={tempSelectedPost?.title ?? "-"}
									disabled={true}
									readOnly
								/>
							</FormGroup>
							<Card className="mb-3">
								<CardHeader>
									<div className="d-flex align-items-end w-100">
										<span>Video</span>
										<div className="ml-auto">
											<Button
												size={"sm"}
												disabled={postVideos.length > 4}
												color={"primary"}
												onClick={() => setShowAddVideo(true)}>
												<AiOutlinePlus style={{ width: 12, height: 12 }} />
											</Button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									{postVideos.length < 1 && (
										<div className="text-center">No videos is selected</div>
									)}
									{postVideos.map((videoChild, videoIndex) => (
										<div className="card mb-2">
											<div className="p-2 d-flex">
												<span style={{ width: "max-content" }}>
													{videoChild.url}
												</span>
												<AiFillEdit
													style={{ cursor: "pointer" }}
													className="text-primary mr-2 ml-auto"
													onClick={() => {
														setSelectedPostVideoIndex(videoIndex);
													}}
												/>
												<AiFillDelete
													style={{ cursor: "pointer" }}
													className="text-danger"
													onClick={() => {
														let temp = _.cloneDeep(postVideos);
														temp.splice(videoIndex, 1);

														setPostVideo(temp);
													}}
												/>
											</div>
										</div>
									))}
								</CardBody>
							</Card>

							<BulkImageField
								imageData={imageData}
								onRemove={(url, index) => {
									let temp = _.cloneDeep(imageData);
									temp.splice(index, 1);

									setImageData(temp);
								}}
								onAddFile={(val) => {
									let temp = _.cloneDeep(imageData);
									temp.push({
										url: val,
									});

									setImageData(temp);
								}}
							/>
						</Col>
						<Col md={12} lg={6} xl={4} className="mywheels-post-form">
							<Card className="mb-3">
								<CardHeader>Article Details</CardHeader>
								<CardBody>
									<FormGroup>
										<Label>Schedule Date Time</Label>
										<DatePicker
											selected={
												postForm.start_at ? new Date(postForm.start_at) : null
											}
											showYearDropdown
											className="form-control"
											minDate={Moment().toDate()}
											popperPlacement="right-start"
											onChange={(val) => onChangeField("start_at", val)}
											showTimeSelect
											dateFormat="MMMM d, yyyy h:mm aa"
										/>
									</FormGroup>
									<FormGroup>
										<Label>
											Main Category
											<span className="text-danger">*</span>
										</Label>
										<Input
											type={"select"}
											value={postForm.slug_category_id}
											onChange={(e) =>
												onChangeField("slug_category_id", +e.target.value)
											}>
											<option value=""></option>
											{categories.map((categoryChild) => (
												<option
													key={`category_${categoryChild.id}`}
													value={categoryChild.id}>
													{categoryChild.name}
												</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup>
										<CategoryContent
											categories={categories}
											postCategories={postCategories}
											setPostcategories={setPostcategories}
										/>
									</FormGroup>
								</CardBody>
							</Card>
							<Card className="mb-3">
								<CardHeader>Tags</CardHeader>
								<CardBody>
									{postForm.mywheels_tags.length < 1 && (
										<div className="text-center">No tags is selected</div>
									)}
									<div className="d-flex flex-wrap mb-3" style={{ gap: 5 }}>
										{postForm.mywheels_tags.length > 0 &&
											postForm.mywheels_tags.map((tagChild) => (
												<Fragment key={`tag_${tagChild.id}`}>
													<span
														className="badge badge-white p-2"
														style={{
															border: "1px solid lightgray",
															borderRadius: 15,
														}}>
														{`# `} {tagChild.name}
														<AiOutlineCloseCircle
															className={"bg-white ml-1"}
															style={{
																cursor: "pointer",
																width: 13,
																height: 13,
																borderRadius: "50%",
															}}
															onClick={() => {
																let temp = _.cloneDeep(postForm.mywheels_tags);
																_.remove(temp, (val) => val.id === tagChild.id);

																onChangeField("mywheels_tags", temp);
															}}
														/>
													</span>
												</Fragment>
											))}
									</div>
									<div className="d-flex w-100 alignt-items-center flex-wrap">
										<Typeahead
											ref={typeaheadRef}
											id="basic-example"
											onInputChange={(e) => {
												const matchedOptions = tags.filter((option) =>
													option.label.toLowerCase().includes(e.toLowerCase())
												);

												setAllowCreate(matchedOptions.length < 1);
												setTypeaheadSearch(e);
											}}
											onChange={(val) => {
												onChangeField("mywheels_tags", [
													...postForm.mywheels_tags,
													...val,
												]);
											}}
											options={[
												..._.filter(tags, (tagChild) => {
													let tempIndex =
														_.findIndex(postForm.mywheels_tags, {
															id: tagChild.id,
														}) < 0;

													return tempIndex;
												}),
											]}
											placeholder="Choose a tag"
											selected={[]}
										/>
										<Button
											size={"sm"}
											className="ml-auto"
											disabled={!typeaheadSearch || !allowToCreateTag}
											onClick={() => {
												createTag(
													{
														name: typeaheadSearch,
													},
													(val) => {
														typeaheadRef.current.clear();
														onChangeField("mywheels_tags", [
															...postForm.mywheels_tags,
															val,
														]);
													}
												);
											}}>
											Create
										</Button>
									</div>
								</CardBody>
							</Card>
							<Card className="mb-3">
								<CardBody>
									<FormGroup>
										<Label>Photographer</Label>
										<Input
											value={postForm.photographer_name}
											onChange={(e) =>
												onChangeField("photographer_name", e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Label>Reporter</Label>
										<Input
											value={postForm.author_name}
											onChange={(e) =>
												onChangeField("author_name", e.target.value)
											}
										/>
									</FormGroup>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "primary",
						content: "Save As Draft",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							let temp = _.cloneDeep(postForm);
							temp.status = "draft";
							temp.start_at = null;
							temp.published_at = null;

							onSubmit(temp, postVideos, postCategories, imageData);
						},
					},
					{
						color: "primary",
						content: "Save As Scheduled",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							onSubmit(postForm, postVideos, postCategories, imageData);
						},
					},
					{
						color: "primary",
						content: "Publish Now",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							let temp = _.cloneDeep(postForm);
							temp.published_at = `${Moment()}`;
							temp.start_at = null;
							temp.status = "live";

							onSubmit(temp, postVideos, postCategories, imageData);
						},
					},
				]}
			/>
			<CustomModal
				size={"xl"}
				isOpen={showChoosePost}
				onClose={() => setChoosePost(false)}>
				<FurtherReadingModal
					categories={categories}
					onSubmit={(val) => {
						setTempSelectedPost(val);
						onChangeField("further_reading_post_id", val.id);
						setChoosePost(false);
					}}
					onClose={() => setChoosePost(false)}
				/>
			</CustomModal>
			<CustomModal isOpen={showAddVideo} onClose={() => setShowAddVideo(false)}>
				<VideoModal
					onSubmit={(val) => {
						setPostVideo([...postVideos, val]);
						setShowAddVideo(false);
					}}
					postID={null}
				/>
			</CustomModal>
			<CustomModal
				isOpen={selectedPostVideoIndex !== null}
				onClose={() => setSelectedPostVideoIndex(null)}>
				<VideoModal
					selectedVideo={postVideos[selectedPostVideoIndex]}
					onSubmit={(val) => {
						let temp = _.cloneDeep(postVideos);
						temp[selectedPostVideoIndex] = val;

						setPostVideo(temp);
						setSelectedPostVideoIndex(null);
					}}
					postID={null}
				/>
			</CustomModal>
		</>
	);
}

export const MainNav = [
	{
		icon: "pe-7s-folder",
		label: "Data Drive",
		to: "#/dashboard/drive",
	},
];

export const JobMachaMenu = [
	{
		icon: "pe-7s-pin",
		label: "Post",
		to: "#/dashboard/job-macha/post",
	},
	// {
	// 	icon: "pe-7s-video",
	// 	label: "Video",
	// 	to: "#/dashboard/job-macha/video",
	// },
	{
		icon: "pe-7s-trash",
		label: "Bin",
		to: "#/dashboard/job-macha/bin",
	},
	{
		icon: "pe-7s-ticket",
		label: "Tags",
		to: "#/dashboard/job-macha/tags",
	},
];

export const ServiceMachaMenu = [
	{
		icon: "pe-7s-pin",
		label: "Post",
		to: "#/dashboard/service-macha/post",
	},
	// {
	// 	icon: "pe-7s-video",
	// 	label: "Video",
	// 	to: "#/dashboard/service-macha/video",
	// },
	{
		icon: "pe-7s-trash",
		label: "Bin",
		to: "#/dashboard/service-macha/bin",
	},
	{
		icon: "pe-7s-ticket",
		label: "Tags",
		to: "#/dashboard/service-macha/tags",
	},
];

export const MyWheelsMenu = [
	{
		icon: "pe-7s-pin",
		label: "Post",
		to: "#/dashboard/post",
	},
	{
		icon: "pe-7s-video",
		label: "Video",
		to: "#/dashboard/video",
	},
	{
		icon: "pe-7s-trash",
		label: "Bin",
		to: "#/dashboard/bin",
	},
	{
		icon: "pe-7s-ticket",
		label: "Tags",
		to: "#/dashboard/tags",
	},
	{
		icon: "pe-7s-car",
		label: "Car Brand",
		to: "#/dashboard/car-brand",
	},
	{
		icon: "pe-7s-car",
		label: "Featured Car Model",
		to: "#/dashboard/feature-car-model",
	},
	{
		icon: "pe-7s-car",
		label: "Car Model",
		to: "#/dashboard/car-model",
	},
	{
		icon: "pe-7s-car",
		label: "Petrol",
		to: "#/dashboard/petrol",
	},
];

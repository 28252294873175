import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import _ from "lodash";
import Moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import {
	AiFillDelete,
	AiFillEdit,
	AiOutlineCloseCircle,
	AiOutlinePlus,
} from "react-icons/ai";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import { compose } from "redux";

import ImageField from "components/Input/File";
import RTE from "components/Input/RTE";
import CustomModal from "components/Modal";
import BulkImageField from "../ImageField";
import FurtherReadingModal from "../Modal/FurtherReadingModal";
import VideoModal from "../Modal/VideoModal";
import { SamplePostData } from "../assets";
import CategoryContent from "./Category";

import ImageHOC from "../actions/image";
import VideoHOC from "../actions/video";

import "react-datepicker/dist/react-datepicker.css";

const PostForm = ({
	images,
	userID,
	tags,
	categories,
	onSubmit,
	createTag,

	selectedPost,
	selectedPostTags,
	flattenCategories,
	selectedPostVideo,

	getPostTags,
	addPostTag,
	deletePostTag,
	getPostVideo,
	updatePostVideo,
	deletePostVideo,
	createPostVideo,
	deleteImage,
	createImage,
	getImage,
	addFurtherPost,
	deleteFutherPost
}) => {
	const typeaheadRef = useRef();

	const [typeaheadSearch, setTypeaheadSearch] = useState("");
	const [allowToCreateTag, setAllowCreate] = useState(false);
	const [showFurtherReadingModal, setShowFurtherReadingModal] = useState(null);

	const [postForm, setPostForm] = useState({
		...SamplePostData,
		platform: "jobmacha",
		created_by: userID,
	});
	const [postVideos, setPostVideo] = useState([]);
	const [showAddVideo, setShowAddVideo] = useState(false);
	const [selectedPostVideoIndex, setSelectedPostVideoIndex] = useState(null);

	const onChangeField = (key, val) => {
		setPostForm({
			...postForm,
			[key]: val,
		});
	};

	useEffect(() => {
		if (selectedPost) {
			getImage(selectedPost.id);
			getPostTags(selectedPost.id);
			getPostVideo(selectedPost.id);
		}
	}, [selectedPost]);

	useEffect(() => {
		if (selectedPost && selectedPostTags) {
			setPostForm({
				...selectedPost,
				mywheels_tags: selectedPostTags,
			});
		}
	}, [selectedPost, selectedPostTags]);

	useEffect(() => {
		setPostVideo(selectedPostVideo);
	}, [selectedPostVideo]);

	return (
		<>
			<CustomModalBody fullScreen={true}>
				<Form>
					<Row>
						<Col md={12} lg={6} xl={8}>
							<FormGroup>
								<Label>
									Title
									<span className="text-danger">*</span>
								</Label>
								<Input
									placeholder="Add Title"
									value={postForm.title}
									onChange={(e) => onChangeField("title", e.target.value)}
								/>
							</FormGroup>
							<FormGroup>
								<RTE
									data={postForm.content}
									height={"500px"}
									formLabel={"Post Content"}
									disabled={false}
									onChange={(event, editor) =>
										onChangeField("content", editor.getData() || "")
									}
								/>
							</FormGroup>
							<FormGroup>
								<ImageField
									label={"Cover Image with Title"}
									fileValue={postForm.cover_img_with_title_url}
									onRemoveFile={() =>
										onChangeField("cover_img_with_title_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("cover_img_with_title_url", val)
									}
								/>
							</FormGroup>
							<FormGroup>
								<ImageField
									label={"Cover Image without Title"}
									fileValue={postForm.cover_img_without_title_url}
									onRemoveFile={() =>
										onChangeField("cover_img_without_title_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("cover_img_without_title_url", val)
									}
								/>
							</FormGroup>
							{/* <FormGroup>
								<ImageField
									label={"Article Feature Image"}
									fileValue={postForm.article_feature_img_url}
									onRemoveFile={() =>
										onChangeField("article_feature_img_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("article_feature_img_url", val)
									}
								/>
							</FormGroup> */}
							<div className="card p-3 mb-4">
								<div className="d-flex">
									<Button
										onClick={() => setShowFurtherReadingModal(true)} 
										disabled={selectedPost?.post_further_readings?.[0] && selectedPost?.post_further_readings.length > 4}
										style={{marginLeft:'auto'}}
									>
										Add Futher Reading Post
									</Button>
								</div>
								{
									(!selectedPost?.post_further_readings ) && (
										<div style={{ display: 'grid', placeItems: 'center', height: 200 }}>
											No Further Reading Post is selected yet.
										</div>
									)
								}	
								{
									selectedPost?.post_further_readings?.[0] &&
									selectedPost?.post_further_readings.map((furtherChild, furtherIndex) => (
										<FormGroup key={furtherIndex}>
											<Label>
												{ `Further Reading Posts ${furtherIndex + 1}`}
												<AiFillDelete
													style={{ cursor: "pointer" }}
													className="text-danger ml-2"
													onClick={() => deleteFutherPost(furtherChild.id, selectedPost.slug)}
												/>
											</Label>
											<Input
												value={furtherChild?.title ?? "-"}
												disabled={true}
												readOnly
											/>
										</FormGroup>
									))
								}
							</div>
							<Card className="mb-3">
								<CardHeader>
									<div className="d-flex align-items-end w-100">
										<span>Video</span>
										<div className="ml-auto">
											<Button
												size={"sm"}
												disabled={postVideos.length > 4}
												color={"primary"}
												onClick={() => setShowAddVideo(true)}>
												<AiOutlinePlus style={{ width: 12, height: 12 }} />
											</Button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									{postVideos.length < 1 && (
										<div className="text-center">No videos is selected</div>
									)}
									{postVideos.map((videoChild, videoIndex) => (
										<div key={videoIndex} className="card mb-2">
											<div className="p-2 d-flex">
												<span style={{ width: "max-content" }}>
													{videoChild.url}
												</span>
												<AiFillEdit
													style={{ cursor: "pointer" }}
													className="text-primary mr-2 ml-auto"
													onClick={() => {
														setSelectedPostVideoIndex(videoIndex);
													}}
												/>
												<AiFillDelete
													style={{ cursor: "pointer" }}
													className="text-danger"
													onClick={() =>
														deletePostVideo(videoChild.id, videoChild.post_id)
													}
												/>
											</div>
										</div>
									))}
								</CardBody>
							</Card>
							<BulkImageField
								imageData={images}
								onRemove={(url, index) => {
									deleteImage(images[index].id, selectedPost.id);
								}}
								onAddFile={(val) => {
									if (typeof val === "string") {
										createImage({
											url: val,
											post_id: selectedPost.id,
										});
									} else {
										let tempURL = _.map(val, (valChild) => {
											createImage({
												url: valChild,
												post_id: selectedPost.id,
											});
										});
									}
								}}
							/>
						</Col>
						<Col md={12} lg={6} xl={4} className="mywheels-post-form">
							<Card className="mb-3">
								<CardHeader>Article Details</CardHeader>
								<CardBody>
									<FormGroup>
										<Label>Schedule Date Time</Label>
										<DatePicker
											selected={
												postForm.start_at ? new Date(postForm.start_at) : null
											}
											minDate={Moment().toDate()}
											showYearDropdown
											className="form-control"
											popperPlacement="right-start"
											onChange={(val) => onChangeField("start_at", val)}
											showTimeSelect
											dateFormat="MMMM d, yyyy h:mm aa"
										/>
									</FormGroup>
									<FormGroup>
										<Label>
											Main Category
											<span className="text-danger">*</span>
										</Label>
										<Input
											type={"select"}
											value={postForm.slug_category_id}
											onChange={(e) =>
												onChangeField("slug_category_id", +e.target.value)
											}>
											<option value=""></option>
											{categories.map((categoryChild) => (
												<option
													key={`category_${categoryChild.id}`}
													value={categoryChild.id}>
													{categoryChild.name}
												</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup>
										<CategoryContent
											selectedPost={selectedPost}
											categories={categories}
										/>
									</FormGroup>
									<Form>
										<FormGroup check>
											<Input
												name="dont-pin"
												checked={!postForm.position && !postForm.is_pin}
												type="radio"
												onClick={() => {
													let temp = _.cloneDeep(postForm);
													temp.is_pin = false;
													delete temp.position;

													setPostForm(temp);
												}}
											/>{" "}
											<Label check>Don't Pin</Label>
										</FormGroup>
										{[1, 2, 3, 4].map((index) => (
											<FormGroup key={index} check>
												<Input
													name={`radio${index}`}
													type="radio"
													checked={
														postForm.position === index && postForm.is_pin
													}
													onClick={() => {
														let temp = _.cloneDeep(postForm);
														temp.is_pin = true;
														temp.position = index;

														setPostForm(temp);
													}}
												/>{" "}
												<Label check>Pin at Slider No. {` ${index}`}</Label>
											</FormGroup>
										))}
									</Form>
									<FormGroup>
										<Label>Shorten URL</Label>
										<Input
											value={`https://jobmacha-nextjs-dev.navarch.app/articles${postForm.short_url}`}
											disabled={true}
											readOnly={true}
										/>
									</FormGroup>
								</CardBody>
							</Card>
							<Card className="mb-3">
								<CardHeader>Tags</CardHeader>
								<CardBody>
									{postForm.mywheels_tags.length < 1 && (
										<div className="text-center">No tags is selected</div>
									)}
									<div className="d-flex flex-wrap mb-3" style={{ gap: 5 }}>
										{postForm.mywheels_tags.length > 0 &&
											postForm.mywheels_tags.map((tagChild) => (
												<Fragment key={`tag_${tagChild.id}`}>
													<span
														className="badge badge-white p-2"
														style={{
															border: "1px solid lightgray",
															borderRadius: 15,
														}}>
														{`# `} {tagChild.name}
														<AiOutlineCloseCircle
															className={"bg-white ml-1"}
															style={{
																cursor: "pointer",
																width: 13,
																height: 13,
																borderRadius: "50%",
															}}
															onClick={() => {
																deletePostTag(tagChild.id, tagChild.post_id);
															}}
														/>
													</span>
												</Fragment>
											))}
									</div>
									<div className="d-flex w-100 alignt-items-center flex-wrap">
										<Typeahead
											ref={typeaheadRef}
											id="basic-example"
											onInputChange={(e) => {
												const matchedOptions = tags.filter((option) =>
													option.label.toLowerCase().includes(e.toLowerCase())
												);

												setAllowCreate(matchedOptions.length < 1);
												setTypeaheadSearch(e);
											}}
											onChange={(val) => {
												addPostTag(selectedPost.id, val?.[0]?.id);
											}}
											options={[
												..._.filter(tags, (tagChild) => {
													let tempIndex =
														_.findIndex(postForm.mywheels_tags, {
															jobmacha_tag_id: tagChild.id,
														}) < 0;

													return tempIndex;
												}),
											]}
											placeholder="Choose a tag"
											selected={[]}
										/>
										<Button
											size={"sm"}
											className="ml-auto"
											disabled={!typeaheadSearch || !allowToCreateTag}
											onClick={() => {
												createTag(
													{
														name: typeaheadSearch,
													},
													(val) => {
														typeaheadRef.current.clear();
														addPostTag(selectedPost.id, val.id);
													}
												);
											}}>
											Create
										</Button>
									</div>
								</CardBody>
							</Card>
							<Card className="mb-3">
								<CardBody>
									<FormGroup>
										<Label>Photographer</Label>
										<Input
											value={postForm.photographer_name}
											onChange={(e) =>
												onChangeField("photographer_name", e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Label>Reporter</Label>
										<Input
											value={postForm.author_name}
											onChange={(e) =>
												onChangeField("author_name", e.target.value)
											}
										/>
									</FormGroup>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "primary",
						content: "Save",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							onSubmit({ ...postForm });
						},
					},
					{
						color: "primary",
						content: postForm.status !== "live" ? "Publish Now" : "Unpublish",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							let tempForm = _.cloneDeep(postForm);
							tempForm.status = postForm.status === "live" ? "draft" : "live";

							if (tempForm.status === "live") {
								tempForm.published_at = `${Moment()}`;
							}
							tempForm.start_at = null;

							onSubmit(tempForm);
						},
					},
					{
						color: "warning",
						content: <span className="text-white">Preview</span>,
						onClick: () =>
							window.open(
								`https://jobmacha-nextjs-dev.navarch.app/preview${selectedPost.slug}`,
								"_blank"
							),
					},
				]}
			/>
			<CustomModal isOpen={showAddVideo} onClose={() => setShowAddVideo(false)}>
				<VideoModal
					onSubmit={(val) => {
						createPostVideo(val);
						setShowAddVideo(false);
					}}
					postID={selectedPost?.id}
				/>
			</CustomModal>
			<CustomModal
				isOpen={selectedPostVideoIndex !== null}
				onClose={() => setSelectedPostVideoIndex(null)}>
				<VideoModal
					selectedVideo={postVideos[selectedPostVideoIndex]}
					onSubmit={(val, id) => {
						updatePostVideo(val, id);
						setSelectedPostVideoIndex(null);
					}}
					postID={selectedPost?.id}
				/>
			</CustomModal>
			<CustomModal
				size={"xl"}
				isOpen={ showFurtherReadingModal }
				onClose={() => setShowFurtherReadingModal(false)}
			>
				<FurtherReadingModal
					flattenCategories={flattenCategories}
					onSubmit={(val) => {
						addFurtherPost({
							post_id: selectedPost.id,
							further_reading_post_id: val.id
						}, selectedPost.slug)

						setShowFurtherReadingModal(false);
					}}
					onClose={() => setShowFurtherReadingModal(false)}
				/>
			</CustomModal>
		</>
	);
};

export default compose(ImageHOC, VideoHOC)(PostForm);

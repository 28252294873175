import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import _ from "lodash";

import ImageField from "components/Input/File";
import ChinaPressModalBody from "components/Modal/body";
import ChinaPressModalFooter from "components/Modal/footer";

function AddImgModal({ onSubmit }) {
	const [tempUrl, setTempUrl] = useState([]);

	const onHandleRemove = (index) => {
		let temp = _.cloneDeep(tempUrl);
		temp.split(index, 1);
		setTempUrl(temp);
	};

	return (
		<>
			<ChinaPressModalBody>
				<FormGroup>
					<ImageField
						label={""}
						multiple={true}
						fileValue={tempUrl}
						onRemoveFile={(index) => onHandleRemove(index)}
						onSelectImage={(val) => setTempUrl(val)}
					/>
				</FormGroup>
			</ChinaPressModalBody>
			<ChinaPressModalFooter
				rightButton={[
					{
						color: "primary",
						disabled: !tempUrl,
						content: "Submit",
						onClick: () => onSubmit(tempUrl),
					},
				]}
			/>
		</>
	);
}

export default AddImgModal;

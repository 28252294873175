import React, { useState, useEffect, useRef } from "react";
import {
	Button,
	Label,
	FormGroup,
	FormFeedback,
	UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import Axios from "axios";
import { BsFillImageFill } from "react-icons/bs";
import MediaDB from "components/Modal/MediaDB";
import getDomainURL from "utils/api";
import LoadingIndicator from "components/Indicator/LoadingOverlay";
import "./rte.scss";

const CKEditor5 = (props) => {
	const editorRef = useRef();
	const testEditorRef = useRef();
	const [editorLoaded, setEditorLoaded] = useState(false);
	const { CKEditor, CustomEditor } = editorRef.current || {};
	const [isOpenMediaSelector, toggleMediaSelector] = useState(false);

	useEffect(() => {
		editorRef.current = {
			CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
			CustomEditor: require("ckeditor5-custom-build/build/ckeditor"),
		};
		setEditorLoaded(true);
	}, []);

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const body = new FormData();
					loader.file.then((file) => {
						let reader = new FileReader();
						reader.onload = (e) => {
							let token = localStorage["CHINA_PRESS_TOKEN"];
							Axios.defaults.headers = {
								"Access-Control-Allow-Origin": "*",
								Authorization: `${token}`,
							};
							Axios.post(`${getDomainURL()}/assets`, {
								base64: e.target.result,
								title: file.name,
								alt: file.name,
								filename: file.name,
								platforms: [props.storeData.ProfileReducer.profile.platform],
							})
								.then((res) => {
									resolve({
										default: res.data.data.url,
									});
								})
								.catch((err) => {
									if (err && err.response && err.response.status === 422) {
										reject(err);
									} else {
										reject(
											"You are disconnnected from the internet, please reconnect to use the system. If problem persists, please contact the system admin."
										);
									}
								});
						};
						reader.readAsDataURL(file);
					});
				});
			},
		};
	}

	return editorLoaded ? (
		<>
			<FormGroup
				className="ckrte"
				onMouseOut={() => props.onChange(null, testEditorRef.current.editor)}>
				<Label className="d-flex align-items-end">
					{props.formLabel}
					<div className="ml-auto">
						<Button
							color="primary"
							id={"mywheels-append-img"}
							onClick={() => {
								toggleMediaSelector(true);
							}}>
							<BsFillImageFill />
							<UncontrolledTooltip target="mywheels-append-img">
								Append Image from Drive
							</UncontrolledTooltip>
						</Button>
					</div>
				</Label>
				<CKEditor
					ref={testEditorRef}
					onReady={(editor) => {
						editor.editing.view.change((writer) => {
							writer.setStyle(
								{
									height: props.height || "400px",
									backgroundColor: props.backgroundColor,
								},
								editor.editing.view.document.getRoot()
							);
						});
					}}
					config={{
						extraPlugins: [uploadPlugin],
						toolbar: {
							items: props.items
								? props.items
								: [
										"fontSize",
										"fontColor",
										"fontBackgroundColor",
										"|",
										"undo",
										"redo",
										"|",
										"link",
										"|",
										"insertTable",
										"horizontalLine",
										"|",
										"bold",
										"italic",
										"strikethrough",
										"|",
										"numberedList",
										"bulletedList",
										"|",
										"indent",
										"outdent",
										"|",
										"blockQuote",
										"|",
										"heading",
										"alignment",
										"|",
										"uploadImage", // Add the uploadImage tool
										"mediaEmbed",
								  ],
							shouldNotGroupWhenFull:
								props.shouldNotGroupWhenFull !== undefined
									? props.shouldNotGroupWhenFull
									: true,
						},
						image: {
							toolbar: [
								"imageStyle:inline",
								"imageStyle:block",
								"imageStyle:side",
								"|",
								"toggleImageCaption",
								"imageTextAlternative",
								"resizeImage",
							],
						},
						mediaEmbed: {
							previewsInData: true,
							removeProviders: [
								"instagram",
								"twitter",
								"googleMaps",
								"flickr",
								"facebook",
							],
							extraProviders: [
								{
									name: "twit",
									url: [/^https:\/\/twitter\.com\/[^/]+\/status\/\d+$/],
									html: (match) => {
										return (
											'<div style="position: relative; padding-bottom: 20%; height: max-content;">' +
											`<blockquote hide_thread=true data-conversation="none" cards = hidden align = center class="twitter-tweet">
											<a href="${match.input}">
											${match.input}
											</a>
										</blockquote>` +
											"</div>"
										);
									},
								},
								{
									name: "fb",
									url: [
										/https?:\/\/www\.facebook\.com\/plugins\/video\.php\?.*href=([^&]+)/,
									],
									html: (match) => {
										return (
											'<div style="position: relative; padding-bottom: 56%; height: max-content;">' +
											`<iframe src="${match.input}" ` +
											'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
											'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
											"</iframe>" +
											"</div>"
										);
									},
								},
							],
						},
						link: {
							decorators: {
								openInNewTab: {
									mode: "manual",
									label: "Open in a new tab",
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									},
								},
							},
						},
					}}
					editor={CustomEditor.ClassicEditor}
					data={props.data}
					onFocus={props.onFocus}
				/>
				<MediaDB
					multiSelect={true}
					isOpen={isOpenMediaSelector}
					onSelect={(val, type) => {
						if (type !== "video") {
							testEditorRef.current.editor.execute("insertImage", {
								source: val,
							});
						} else {
							testEditorRef.current.editor.execute(
								"htmlEmbed",
								`<video style="width: 100%;" controls src="${val[0]}"/>`
							);
						}
						toggleMediaSelector(false);
						props.onChange(null, testEditorRef.current.editor);
					}}
					onClose={() => toggleMediaSelector(false)}
				/>
				<FormFeedback className={props.invalid ? "d-block" : ""}>
					{props.invalid}
				</FormFeedback>
			</FormGroup>
		</>
	) : (
		<LoadingIndicator />
	);
};

const mapStateToProps = (state) => ({ storeData: state });
export default connect(mapStateToProps)(CKEditor5);

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			posts: [],
			pageIndex: 1,
			flattenCategories: [],
			postCounts: 0,
			searchParams: [
				{
					label: "Title",
					value: `title`,
					type: "text",
					param: ``,
					col: 6,
				},
				{
					label: "From Date",
					value: `start_at`,
					type: "date",
					param: `${Moment().year(2010)}`,
					defaultVal: `${Moment().year(2010)}`,
					col: 6,
				},
				{
					label: "Category",
					value: `slug_category_id`,
					type: "select",
					options: [],
					param: "",
					col: 6,
				},
			],
		};

		onChangePostHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getPost = (val) => {
			Get(
				`/non-deleted-posts?datetype=between${
					!val
						? `&type=post&platform=myWheels&offset=${
								(this.state.pageIndex - 1) * 10
						  }&limit=10`
						: `${val}&type=post&platform=myWheels&offset=${
								(this.state.pageIndex - 1) * 10
						  }&limit=10`
				}`,
				this.getPostSuccess,
				this.getPostError,
				this.load
			);
		};
		getPostSuccess = (payload) => {
			this.setState({
				posts: payload.data.rows,
				postCounts: payload.data.count,
			});
		};
		getPostError = (error) => requestError(error);

		handleGetCategoryPromise = (index) => {
			return new Promise((resolve, reject) => {
				Get(
					`/mywheels/find-children-categories/${index + 1}`,
					(payload) => {
						resolve(payload.data);
					},
					(error) => {
						reject(error);
					},
					this.load
				);
			});
		};

		getCategories = () => {
			const apiPromises = [];
			for (let index = 0; index < 4; index++) {
				apiPromises.push(this.handleGetCategoryPromise(index));
			}

			Promise.all(apiPromises)
				.then((responses) => {
					const tempFlatten = responses.flat();
					let tempFiltered = _.filter(tempFlatten, (val) => val.parent_id);

					this.setState({
						flattenCategories: tempFiltered,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					postCounts={this.state.postCounts}
					flattenCategories={this.state.flattenCategories}
					pageIndex={this.state.pageIndex}
					posts={this.state.posts}
					searchParams={this.state.searchParams}
					onLoadPost={this.state.loading}
					getPost={this.getPost}
					getCategories={this.getCategories}
					onChangePostHOC={this.onChangePostHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;

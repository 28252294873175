import React, { useState, Fragment, useRef } from "react";
import _ from "lodash";
import Moment from "moment";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import {
	Form,
	Input,
	FormGroup,
	Label,
	Row,
	Col,
	CardBody,
	Card,
	CardHeader,
	Button,
} from "reactstrap";
import { AiOutlinePlus, AiOutlineCloseCircle } from "react-icons/ai";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";

import { SampleVideoData } from "../assets";
import ImageField from "components/Input/File";
import CustomModal from "components/Modal";
import CategoryContent from "./Category";
import CarModelModal from "components/Modal/CarModel";
import CarModelBrand from "components/Modal/CarBrand";

import "react-datepicker/dist/react-datepicker.css";

export default function VideoForm({
	userID,
	tags,
	flattenCategories,
	categories,
	onSubmit,
	createTag,
}) {
	const typeaheadRef = useRef();
	const [postForm, setVideoForm] = useState({
		...SampleVideoData,
		created_by: userID,
	});
	const [typeaheadSearch, setTypeaheadSearch] = useState("");
	const [allowToCreateTag, setAllowCreate] = useState(false);
	const [postCategories, setPostcategories] = useState([]);

	const [showAddModel, setAddModel] = useState(false);
	const [postCarModels, setCarModel] = useState([]);

	const [showAddBrand, setAddBrand] = useState(false);
	const [postCarBrands, setCarBrands] = useState([]);

	const onChangeField = (key, val) => {
		setVideoForm({
			...postForm,
			[key]: val,
		});
	};

	return (
		<>
			<CustomModalBody fullScreen={true}>
				<Form>
					<Row>
						<Col md={12} lg={6} xl={8}>
							<FormGroup>
								<Label>
									Title
									<span className="text-danger">*</span>
								</Label>
								<Input
									placeholder="Add Title"
									value={postForm.title}
									onChange={(e) => onChangeField("title", e.target.value)}
								/>
							</FormGroup>
							<FormGroup>
								<Label>Video Embed URL</Label>
								<Input
									placeholder="Add Title"
									value={postForm.video_embed_url}
									onChange={(e) =>
										onChangeField("video_embed_url", e.target.value)
									}
								/>
							</FormGroup>
							<FormGroup>
								<Label>Description</Label>
								<Input
									placeholder="Add Title"
									type={"textarea"}
									value={postForm.content}
									onChange={(e) => onChangeField("content", e.target.value)}
								/>
							</FormGroup>
							<FormGroup>
								<ImageField
									label={"Cover Image with Title"}
									fileValue={postForm.cover_img_with_title_url}
									onRemoveFile={() =>
										onChangeField("cover_img_with_title_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("cover_img_with_title_url", val)
									}
								/>
							</FormGroup>
							<FormGroup>
								<ImageField
									label={"Cover Image without Title"}
									fileValue={postForm.cover_img_without_title_url}
									onRemoveFile={() =>
										onChangeField("cover_img_without_title_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("cover_img_without_title_url", val)
									}
								/>
							</FormGroup>
							{/* <FormGroup>
								<ImageField
									label={"Article Feature Image"}
									fileValue={postForm.article_feature_img_url}
									onRemoveFile={() =>
										onChangeField("article_feature_img_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("article_feature_img_url", val)
									}
								/>
							</FormGroup> */}

							<Card className="mb-3">
								<CardHeader>
									<div className="d-flex align-items-end w-100">
										<span>Car Brands</span>
										<div className="ml-auto">
											<Button
												size={"sm"}
												color={"primary"}
												onClick={() => setAddBrand(true)}>
												<AiOutlinePlus style={{ width: 12, height: 12 }} />
											</Button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									{postCarBrands.length < 1 && (
										<div className="text-center">No brands is selected</div>
									)}
									{postCarBrands.map((brandChild, brandIndex) => (
										<Fragment key={`tag_${brandChild.id}`}>
											<span
												className="badge badge-white p-2"
												style={{
													border: "1px solid lightgray",
													borderRadius: 15,
												}}>
												{brandChild.name}
												<AiOutlineCloseCircle
													className={"bg-white ml-1"}
													style={{
														cursor: "pointer",
														width: 13,
														height: 13,
														borderRadius: "50%",
													}}
													onClick={() => {
														let temp = _.cloneDeep(postCarBrands);
														temp.splice(brandIndex, 1);

														setCarModel(temp);
													}}
												/>
											</span>
										</Fragment>
									))}
								</CardBody>
							</Card>

							<Card className="mb-3">
								<CardHeader>
									<div className="d-flex align-items-end w-100">
										<span>Car Models</span>
										<div className="ml-auto">
											<Button
												size={"sm"}
												color={"primary"}
												onClick={() => setAddModel(true)}>
												<AiOutlinePlus style={{ width: 12, height: 12 }} />
											</Button>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									{postCarModels.length < 1 && (
										<div className="text-center">No models is selected</div>
									)}
									{postCarModels.map((modelChild, modelIndex) => (
										<Fragment key={`tag_${modelChild.id}`}>
											<span
												className="badge badge-white p-2"
												style={{
													border: "1px solid lightgray",
													borderRadius: 15,
												}}>
												{modelChild.title}
												<AiOutlineCloseCircle
													className={"bg-white ml-1"}
													style={{
														cursor: "pointer",
														width: 13,
														height: 13,
														borderRadius: "50%",
													}}
													onClick={() => {
														let temp = _.cloneDeep(postCarModels);
														temp.splice(modelIndex, 1);

														setCarModel(temp);
													}}
												/>
											</span>
										</Fragment>
									))}
								</CardBody>
							</Card>
						</Col>
						<Col md={12} lg={6} xl={4} className="mywheels-post-form">
							<Card className="mb-3">
								<CardHeader>Article Details</CardHeader>
								<CardBody>
									<FormGroup>
										<Label>Schedule Date Time</Label>
										<DatePicker
											showYearDropdown
											selected={
												postForm.start_at ? new Date(postForm.start_at) : null
											}
											className="form-control"
											minDate={Moment().toDate()}
											popperPlacement="right-start"
											onChange={(val) => onChangeField("start_at", val)}
											showTimeSelect
											dateFormat="MMMM d, yyyy h:mm aa"
										/>
									</FormGroup>
									<FormGroup>
										<Label>
											Main Category
											<span className="text-danger">*</span>
										</Label>
										<Input
											type={"select"}
											value={postForm.slug_category_id}
											onChange={(e) =>
												onChangeField("slug_category_id", +e.target.value)
											}>
											<option value=""></option>
											{flattenCategories.map((categoryChild) => (
												<option
													key={`category_${categoryChild.id}`}
													value={categoryChild.id}>
													{categoryChild.name}
												</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup>
										<CategoryContent
											categories={categories}
											postCategories={postCategories}
											setPostcategories={setPostcategories}
										/>
									</FormGroup>
									<Form>
										<FormGroup check>
											<Input
												name="dont-pin"
												checked={!postForm.position && !postForm.is_pin}
												type="radio"
												onClick={() => {
													let temp = _.cloneDeep(postForm);
													temp.is_pin = false;
													delete temp.position;

													setVideoForm(temp);
												}}
											/>{" "}
											<Label check>Don't Pin</Label>
										</FormGroup>
										{[1, 2, 3, 4].map((index) => (
											<>
												<FormGroup check>
													<Input
														name={`radio${index}`}
														type="radio"
														checked={
															postForm.position === index && postForm.is_pin
														}
														onClick={() => {
															let temp = _.cloneDeep(postForm);
															temp.is_pin = true;
															temp.position = index;

															setVideoForm(temp);
														}}
													/>{" "}
													<Label check>Pin at Slider No. {` ${index}`}</Label>
												</FormGroup>
											</>
										))}
									</Form>
								</CardBody>
							</Card>
							<Card className="mb-3">
								<CardHeader>Tags</CardHeader>
								<CardBody>
									{postForm.mywheels_tags.length < 1 && (
										<div className="text-center">No tags is selected</div>
									)}
									<div className="d-flex flex-wrap mb-3" style={{ gap: 5 }}>
										{postForm.mywheels_tags.length > 0 &&
											postForm.mywheels_tags.map((tagChild) => (
												<Fragment key={`tag_${tagChild.id}`}>
													<span
														className="badge badge-white p-2"
														style={{
															border: "1px solid lightgray",
															borderRadius: 15,
														}}>
														{`# `} {tagChild.name}
														<AiOutlineCloseCircle
															className={"bg-white ml-1"}
															style={{
																cursor: "pointer",
																width: 13,
																height: 13,
																borderRadius: "50%",
															}}
															onClick={() => {
																let temp = _.cloneDeep(postForm.mywheels_tags);
																_.remove(temp, (val) => val.id === tagChild.id);

																onChangeField("mywheels_tags", temp);
															}}
														/>
													</span>
												</Fragment>
											))}
									</div>
									<div className="d-flex w-100 alignt-items-center flex-wrap">
										<Typeahead
											ref={typeaheadRef}
											id="basic-example"
											onInputChange={(e) => {
												const matchedOptions = tags.filter((option) =>
													option.label.toLowerCase().includes(e.toLowerCase())
												);

												setAllowCreate(matchedOptions.length < 1);
												setTypeaheadSearch(e);
											}}
											onChange={(val) => {
												onChangeField("mywheels_tags", [
													...postForm.mywheels_tags,
													...val,
												]);
											}}
											options={[
												..._.filter(tags, (tagChild) => {
													let tempIndex =
														_.findIndex(postForm.mywheels_tags, {
															id: tagChild.id,
														}) < 0;

													return tempIndex;
												}),
											]}
											placeholder="Choose a tag"
											selected={[]}
										/>
										<Button
											size={"sm"}
											className="ml-auto"
											disabled={!typeaheadSearch || !allowToCreateTag}
											onClick={() => {
												createTag(
													{
														name: typeaheadSearch,
													},
													(val) => {
														typeaheadRef.current.clear();
														onChangeField("mywheels_tags", [
															...postForm.mywheels_tags,
															val,
														]);
													}
												);
											}}>
											Create
										</Button>
									</div>
								</CardBody>
							</Card>
							<Card className="mb-3">
								<CardBody>
									<FormGroup>
										<Label>Photographer</Label>
										<Input
											value={postForm.photographer_name}
											onChange={(e) =>
												onChangeField("photographer_name", e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Label>Reporter</Label>
										<Input
											value={postForm.author_name}
											onChange={(e) =>
												onChangeField("author_name", e.target.value)
											}
										/>
									</FormGroup>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "primary",
						content: "Save As Draft",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							let temp = _.cloneDeep(postForm);
							temp.status = "draft";
							temp.start_at = null;
							temp.published_at = null;

							onSubmit(temp, postCategories, postCarModels, postCarBrands);
						},
					},
					{
						color: "primary",
						content: "Save As Scheduled",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							onSubmit(postForm, postCategories, postCarModels, postCarBrands);
						},
					},
					{
						color: "primary",
						content: "Publish Now",
						disabled: !postForm.title || !postForm.slug_category_id,
						onClick: () => {
							let temp = _.cloneDeep(postForm);
							temp.published_at = `${Moment()}`;
							temp.start_at = null;
							temp.status = "live";

							onSubmit(temp, postCategories, postCarModels, postCarBrands);
						},
					},
				]}
			/>
			<CustomModal isOpen={showAddModel} onClose={() => setAddModel(false)}>
				<CarModelModal
					postCarModels={postCarModels}
					onSubmit={(val) => {
						setAddModel(false);
						setCarModel([...postCarModels, val]);
					}}
				/>
			</CustomModal>
			<CustomModal isOpen={showAddBrand} onClose={() => setAddBrand(false)}>
				<CarModelBrand
					postCarBrands={postCarBrands}
					onSubmit={(val) => {
						setAddBrand(false);
						setCarBrands([...postCarBrands, val]);
					}}
				/>
			</CustomModal>
		</>
	);
}
